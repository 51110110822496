<template>
  <div :class="`${theme} ${align}`">
    <svg v-if="theme === 'wave'" xmlns="http://www.w3.org/2000/svg" viewBox="26.8222 10.921 6.302 9.192"
      preserveAspectRatio="none" width="100%" height="104%">
      <path
        d="M 32 11 H 28.482 C 34.2968 13.2838 31.2162 15.3046 28.652 16.9489 C 27.3992 17.8591 27.2426 17.5654 28.1137 18.9048 L 28.8309 19.9805 L 34 20 L 34 11 Z"
        :stroke="themeColor" :stroke-width="lineWidth * 0.02" :fill="color" />
    </svg>
    <svg v-else-if="theme === 'slash-left'" xmlns="http://www.w3.org/2000/svg" viewBox="17 9 5 14" preserveAspectRatio="none"
      width="100%" height="100%">
      <path d="M 17 9 L 20 23 L 22 23 L 22 9 L 17 9" :stroke="themeColor" :stroke-width="lineWidth * 0.02"
        :fill="color" />
    </svg>
    <svg v-else-if="theme === 'slash-right'" xmlns="http://www.w3.org/2000/svg" viewBox="24.9 9.9 5.2 10.2"
      preserveAspectRatio="none" width="100%" height="104%">
      <path d="M 25 20 L 28 10 L 30 10 L 30 20 L 25 20" :stroke="themeColor" :stroke-width="lineWidth * 0.02"
        :fill="color" />
    </svg>
    <svg v-else-if="theme === 'triangle'" xmlns="http://www.w3.org/2000/svg" viewBox="26.9 13.9 4.2 6.2"
      preserveAspectRatio="none" width="100%" height="104%">
      <path d="M 33 14 L 27 14 L 30 17 L 27 20 L 33 20 L 33 14" :stroke="themeColor" :stroke-width="lineWidth * 0.02"
        :fill="color" />
    </svg>
    <svg v-else-if="theme === 'round'" xmlns="http://www.w3.org/2000/svg" viewBox="26.895 13.906 4.2 6.185"
      preserveAspectRatio="none" width="100%" height="104%">
      <path d="M 26.995 14.006 L 30.995 14.026 L 30.965 19.991 L 27.005 19.991 C 31.35 18.648 30.659 14.964 26.995 14.006"
        :stroke="themeColor" :stroke-width="lineWidth * 0.02" :fill="color" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    themeColor: {
      type: String,
      default: ''
    },
    lineWidth: {
      type: [Number, String],
      default: 1
    },
    align: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style lang="scss" scoped>
.right {
  svg {
    transform: rotateY(180deg);
  }

  &.triangle {
    left: -11% !important;
    right: initial !important;
  }
  &.round {
    left: -11% !important;
    right: initial !important;
  }
  &.wave {
    left: -10% !important;
    right: initial !important;
  }
}

.triangle {
  right: -11% !important;
}
.round {
  right: -11% !important;
}
.wave {
  right: -10% !important;
}
</style>